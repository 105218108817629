
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import ErrorMessageCard from "@/sg_copy/components/ErrorMessageCard.vue";
import {namespace} from "vuex-class";
import Response from "@/sg_copy/model/Response";
import {GrantAppTinyDto} from "@/sg_copy/swagger-generated";

const mySubmissionStore = namespace("mySubmissionStore");

@Component({
  components: {
    ErrorMessageCard,
  },
})
export default class CreateVariationModal extends Vue {
  @Prop() private app!: GrantAppTinyDto;
  private loading = true;
  private error = false;
  private processing = false;
  private variationExistsError = false;

  @mySubmissionStore.Action
  public startVariation;

  created() {
    if (this.app) {
      this.load();
    }
  }

  public load() {
    this.loading = true;
    this.error = false;
    this.processing = false;
    this.loading = false;
  }

  public createVariationForm(bvModalEvent) {
    this.processing = true;
    bvModalEvent.preventDefault(); // prevent closing on ok - success will redirect

    this.startVariation(this.app.id)
      .then(responseDto => {
        if (responseDto.data) {
          this.emitVariationCreated();
          const response = new Response(responseDto.data, null);
          window.open(response.responseLink, "_blank");
          this.$bvModal.hide("create-variation-modal");
        }
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.variationExistsError
        ) {
          this.variationExistsError = true;
        }
        this.error = true;
        this.processing = false;
      });
  }

  @Emit("variation-created")
  public emitVariationCreated() {
    // emits
  }
}
