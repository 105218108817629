
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";

@Component({})
export default class SubmissionsSort extends Vue {
  @Prop() private filter: SubmissionFilter;

  private sortOptions = [
    {value: "INSTANCE_NAME", text: "Funder"},
    {value: "PUBLIC_ID", text: "Application No"},
    {value: "GRANT_ROUND", text: "Round"},
    {value: "PROJECT_TITLE", text: "Project Title"},
    {value: "DUE_DATE", text: "Due Date"},
    {value: "SUBMITTED_DATE", text: "Submitted Date"},
    {value: "STATUS", text: "Status"},
  ];

  @Watch("filter.sortSelected")
  public onSortByChange() {
    this.saveFilterRefresh();
  }

  @Watch("filter.sortAscending")
  public onSortAscendingChange() {
    this.saveFilterRefresh();
  }

  public saveFilterRefresh() {
    this.refresh(this.filter);
  }

  @Emit("sort-refresh")
  public refresh(filter: SubmissionFilter) {
    //emit
  }
}
