
import {Component, Prop, Vue} from "vue-property-decorator";
import ApiErrorUtil from "@/sg_copy/utils/ApiErrorUtil";

@Component
export default class ErrorMessage extends Vue {
  // noinspection JSUnusedLocalSymbols
  private apiErrorUtil: ApiErrorUtil = ApiErrorUtil.getInstance();
  @Prop() private variationMessage: boolean;
}
