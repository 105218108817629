<template>
  <div>
    <font-awesome-icon :icon="entry.icons" :class="entry.color" />
    <span class="ml-2">
      <template v-if="showText">{{ entry.text }}</template>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "AppStatus",
  props: ["status", "no_text"],
  data() {
    return {
      showText: !this.no_text,
      map: new Map([
        [
          "SUBMITTED",
          {
            text: "Submitted",
            icons: ["fas", "check-circle"],
            color: "green",
          },
        ],
        [
          "IN_PROGRESS",
          {
            text: "In Progress",
            icons: ["fas", "spinner"],
            color: "orange",
          },
        ],
        [
          "NOT_STARTED",
          {
            text: "Not Started",
            icons: ["fas", "minus-circle"],
            color: "grey",
          },
        ],
        [
          "INCOMPLETE",
          {
            text: "Incomplete",
            icons: ["fas", "times-circle"],
            color: "red",
          },
        ],
      ]),
    };
  },
  computed: {
    entry() {
      return this.map.get(this.status) || this.map.get("INCOMPLETE");
    },
  },
};
</script>

<style scoped>
.red {
  color: red;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.grey {
  color: grey;
}
</style>
