
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";
import {UserDto} from "@/sg_copy/swagger-generated";
import SimpleSearch from "@/sg_copy/components/SimpleSearch.vue";
import SubmissionsSort from "@/sg_copy/components/SubmissionsSort.vue";
import AdvancedSearch from "@/sg_copy/components/AdvancedSearch.vue";

@Component({
  components: {AdvancedSearch, SubmissionsSort, SimpleSearch},
})
export default class SubmissionsSearch extends Vue {
  @Prop() private user: UserDto;

  private filter: SubmissionFilter = new SubmissionFilter();

  public displayAdvancedSearch() {
    this.filter.showSummarisedSearch = false;
    this.filter.showAdvancedSearch = true;
    this.filter.showSimpleSearch = false;
    this.filter.clearSimpleSearch();
  }

  public displaySimpleSearch() {
    this.filter.showSimpleSearch = true;
    this.filter.showAdvancedSearch = false;
    this.filter.showSummarisedSearch = false;
    this.clearAdvancedSearch();
    this.saveFilterRefresh();
  }

  public saveFilterRefresh() {
    this.refresh(this.filter);
  }

  @Emit("refresh")
  public refresh(filter: SubmissionFilter) {
    //emit
  }

  public onFilterChange(filter: SubmissionFilter) {
    this.filter = filter;
    this.saveFilterRefresh();
  }

  public onDisplaySimpleSearch(filter: SubmissionFilter) {
    this.filter = filter;
    this.displaySimpleSearch();
  }

  public clearAdvancedSearch() {
    this.filter.clearAdvancedSearch();
  }
}
