import {ApplicantOrgDto, SfPermission} from "@/sg_copy/swagger-generated";

export class PermissionService {
  static permissionToDescription = new Map<SfPermission, string>([
    [SfPermission.ADMIN, "Manage Organisation"],
    [SfPermission.OF_VIEW, "View Organisation"],
    [SfPermission.GRANT_APP_CREATE, "Create"],
    [SfPermission.GRANT_APP_WRITE, "Edit"],
    [SfPermission.GRANT_APP_READ, "View"],
    [SfPermission.GRANT_APP_SUBMIT, "Submit"],
    [SfPermission.MAYBE_SUBMIT, ""], // Using this permission outside of SF is probably a mistake
  ]);

  public getDescriptionFor(permission: SfPermission): string {
    return PermissionService.permissionToDescription.get(permission);
  }

  public hasPermissions(
    role: ApplicantOrgDto,
    wantedPermissions: Array<SfPermission>
  ): boolean {
    return this.anyMatch(role.permissions, wantedPermissions);
  }

  public anyMatch(
    actualPermissions: Array<SfPermission>,
    wantedPermissions: Array<SfPermission>
  ): boolean {
    return actualPermissions.some(
      permission => wantedPermissions.indexOf(permission) >= 0
    );
  }
}

export const permissionService = new PermissionService();
