import {v4 as uuid} from "uuid";

export default class SubmissionFilter {
  public id: string = uuid();
  public sortSelected:
    | "INSTANCE_NAME"
    | "PUBLIC_ID"
    | "GRANT_ROUND"
    | "PROJECT_TITLE"
    | "DUE_DATE"
    | "SUBMITTED_DATE"
    | "STATUS" = "DUE_DATE";
  public sortAscending = true;
  public searchText = "";

  //advanced search
  public orgSelected: string = null;
  public statusSelected: number = null;
  public roundSelected: string = null;
  public ownedBySelected: string = null;
  public sharedWithSelected: string = null;
  public formNameSelected: string = null;
  public responseSelected: string = null;
  public submittedDateFrom: string = null;
  public submittedDateTo: string = null;
  public dueDateFrom: string = null;
  public dueDateTo: string = null;
  public showAdvancedSearch = false;
  public showSummarisedSearch = false;
  public showSimpleSearch = true;
  public instanceSelected = null;
  public classieOrgIdSelected: number = null;
  public regionSelected: string = null;

  public clearSimpleSearch(): void {
    this.searchText = "";
  }

  public clearAdvancedSearch(): void {
    this.orgSelected = null;
    this.statusSelected = null;
    this.roundSelected = null;
    this.ownedBySelected = null;
    this.sharedWithSelected = null;
    this.formNameSelected = null;
    this.responseSelected = null;
    this.submittedDateFrom = null;
    this.submittedDateTo = null;
    this.dueDateFrom = null;
    this.dueDateTo = null;
    this.instanceSelected = null;
    this.classieOrgIdSelected = null;
    this.regionSelected = null;
  }
}
