import { render, staticRenderFns } from "./AppSubmissionExpandedCard.vue?vue&type=template&id=8596b6ac&scoped=true"
import script from "./AppSubmissionExpandedCard.vue?vue&type=script&lang=ts"
export * from "./AppSubmissionExpandedCard.vue?vue&type=script&lang=ts"
import style0 from "./AppSubmissionExpandedCard.vue?vue&type=style&index=0&id=8596b6ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8596b6ac",
  null
  
)

export default component.exports