
import {Component, Prop, Vue} from "vue-property-decorator";
import Response from "@/sg_copy/model/Response";
import {namespace} from "vuex-class";
import handleApiError from "@/shared/apiErrorUtil";
import FileSaver from "file-saver";

const mySubmissionStore = namespace("mySubmissionStore");

@Component({})
export default class ResponseCard extends Vue {
  @Prop() private response!: Response;
  @Prop() private applicationId: string;
  private loading = false;

  @mySubmissionStore.Action
  public downloadPdf;

  public callDownloadPdfApi(): void {
    this.loading = true;

    this.downloadPdf(this.response.responseId)
      .then(response => {
        FileSaver.saveAs(
          new Blob([response.data], {type: "application/pdf"}),
          this.applicationId + " - " + this.response.formName + ".pdf"
        );
        this.loading = false;
      })
      .catch(error => {
        handleApiError(error, this);
        this.loading = false;
      });
  }
}
