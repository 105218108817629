
import {Component, Vue} from "vue-property-decorator";
import Submissions from "@/sg_copy/components/Submissions.vue";
import {UserDto} from "@/sg_copy/swagger-generated";
import {namespace} from "vuex-class";
import handleApiError from "@/shared/apiErrorUtil";

const orgStore = namespace("orgStore");
const auth = namespace("auth");
const mySubmissionStore = namespace("mySubmissionStore");

@Component({
  components: {
    Submissions: Submissions,
  },
})
export default class SubmissionTab extends Vue {
  private user: UserDto = null;

  @orgStore.State
  private orgs;

  @orgStore.Action
  private fetchOrgs;

  @auth.Getter
  public getCurrentUser;

  @mySubmissionStore.Action
  public getUserInstances;

  mounted() {
    this.fetchOrgs()
      .then(this.getUserInstances)
      .then(response => {
        this.user = {
          applicantOrgs: this.orgs.map(org => ({
            orgId: org.id,
            orgName: org.name,
          })),
          email: this.getCurrentUser.email,
          instances: response.data,
        };
      })
      .catch(error => handleApiError(error, this));
  }
}
