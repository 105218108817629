import NotificationStore, {
  NotificationModel,
} from "@/sg_copy/store/NotificationStore";
import GrantApp from "@/sg_copy/model/GrantApp";
import {getModule} from "vuex-module-decorators";
import store from "@/sg_copy/store";

export default class ApiErrorUtil {
  private static instance: ApiErrorUtil;

  public readonly SERVICE = "service@smartygrants.com.au";

  public readonly SERVICE_TEAM_MESSAGE: string =
    "Please reload your browser. If problem persists, contact " +
    this.SERVICE +
    ".";

  public readonly APP_NOT_AVAILABLE: string =
    "The application may no longer be available to you.";

  public readonly SUBMISSION_LIST_ERROR_TITLE: string =
    "Unable to load applications";

  public readonly SUBMISSION_LIST_ERROR_HTML_MESSAGE: string =
    "<p>We can't display the applications list right now.</p><p>" +
    this.SERVICE_TEAM_MESSAGE +
    ".</p>";

  public readonly VARIATION_EXISTS_MESSAGE: string =
    "There is currently a variation request for this submission either " +
    "in progress or submitted and under review. If changes are required " +
    "to an existing request, please contact the funder as soon as possible.";

  public static getInstance(): ApiErrorUtil {
    if (!ApiErrorUtil.instance) {
      ApiErrorUtil.instance = new ApiErrorUtil();
    }
    return ApiErrorUtil.instance;
  }

  public showApiError(app: GrantApp, title: string): void {
    this.showApiErrorWithMessage(app, title, null);
  }

  public showApiErrorWithMessage(
    app: GrantApp,
    title: string,
    message: string
  ): void {
    if (app) {
      app.processing = false;
    }
    getModule(NotificationStore, store).showApiError(
      this.createApiErrorNotification(title, message)
    );
  }

  private createApiErrorNotification(
    errorHeader: string,
    errorMessage: string
  ): NotificationModel {
    return new (class implements NotificationModel {
      title: string = errorHeader;
      message: string = errorMessage;
    })();
  }
}

export const apiErrorUtil = ApiErrorUtil.getInstance();
