export const tooltipMixin = {
  directives: {
    tooltip: {
      bind: function (el, binding) {
        el.style.display = "inline-block";
        el.addEventListener("mouseover", function (evt) {
          const targetEl = evt.target;
          targetEl.setAttribute("title", binding.value);
        });
      },
    },
  },
};
