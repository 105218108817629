
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import Response from "@/sg_copy/model/Response";
import {tooltipMixin} from "@/sg_copy/mixins/TooltipMixin";
import AppStatus from "@/sg_copy/components/AppStatus.vue";

@Component({
  components: {AppStatus},
  mixins: [tooltipMixin],
})
export default class ResponseSummaryCard extends Vue {
  @Prop() private response!: Response;

  public openResponse() {
    this.emitOpenResponse();
    this.$nextTick(() => {
      window.open(this.response.responseLink, "_blank");
    });
  }

  @Emit("open-response")
  public emitOpenResponse() {
    //emits
  }
}
