
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import handleApiError from "@/shared/apiErrorUtil";
import GrantApp from "@/sg_copy/model/GrantApp";
import {GrantAppDetailDto} from "@/sg_copy/swagger-generated";
import {namespace} from "vuex-class";

const mySubmissionStore = namespace("mySubmissionStore");

@Component
export default class ChildAppDropdownMenu extends Vue {
  @Prop() private mainApp!: GrantApp;
  @Prop() private childAppDetail!: GrantAppDetailDto;

  @mySubmissionStore.Action
  public deleteApp;

  public get deleteAllowed(): boolean {
    return (
      this.childAppDetail.userPermission &&
      this.childAppDetail.userPermission.deleteAllowed
    );
  }

  public deleteChildAppClickHandler(
    mainApp: GrantApp,
    childAppDetail: GrantAppDetailDto
  ): void {
    if (childAppDetail.userPermission.deleteAllowed) {
      const h = this.$createElement;
      const messageVNode = h("div", [
        h("div", {
          domProps: {
            innerHTML:
              "<b>Are you sure you want to delete Application No. " +
              this.header(childAppDetail) +
              "?</b></br>" +
              "<i>The application will be permanently deleted. You can't undo this action.</i>",
          },
        }),
      ]);

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          id: "deleteModal",
          title: "Delete Submission",
          okTitle: "Delete",
          okVariant: "danger",
          centered: true,
        })
        .then(response => {
          if (response) {
            this.deleteChildApp(mainApp, childAppDetail);
          }
        });
    }
  }

  public header(childApp: GrantAppDetailDto): string {
    return (
      childApp.application +
      (childApp.projectTitle ? " - " + childApp.projectTitle : "")
    );
  }

  @Emit("child-app-deleted")
  public emitChildAppDeletedEvent(): void {
    //emits
  }

  public deleteChildApp(mainApp: GrantApp, childApp: GrantAppDetailDto): void {
    mainApp.processing = true;
    this.deleteApp(childApp.applicationId)
      .then(() => {
        childApp.removed = true;
        mainApp.processing = false;
        this.emitChildAppDeletedEvent();

        const message = "Application " + mainApp.header + " has been deleted";
        this.$bvToast.toast(message, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      })
      .catch(error => {
        handleApiError(
          error,
          this,
          "Error deleting application " + mainApp.header
        );
      })
      .finally(() => (mainApp.processing = false));
  }
}
