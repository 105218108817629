
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";
import { SimpleIdNameDto, UserDto } from "@/sg_copy/swagger-generated";
import FilterTags from "@/sg_copy/components/FilterTags.vue";
import { namespace } from "vuex-class";

const mySubmissionStore = namespace("mySubmissionStore");

@Component({
  components: {FilterTags},
})
export default class AdvancedSearch extends Vue {
  @Prop() private filter: SubmissionFilter;
  @Prop() private user: UserDto;
  private classieOrgTypeOptions: SimpleIdNameDto[];
  private regionOptions: string[];

  @mySubmissionStore.Action
  public getClassieOrgTypes;

  @mySubmissionStore.Action
  public getRegions;

  private statusOptions = [
    {value: 1, text: "Submitted"},
    {value: 0, text: "In progress"},
  ];

  mounted() {
    this.getClassieOrgTypes().then(r => {
      this.classieOrgTypeOptions = r.data;
    });
    this.getRegions().then(r => {
      this.regionOptions = r.data;
    });
  }

  public clearAdvancedSearch() {
    this.filter.clearAdvancedSearch();
  }

  public onDisplaySimpleSearch(filter: SubmissionFilter) {
    this.filter = filter;
    this.displaySimpleSearch();
  }

  public displaySummarisedSearch() {
    this.filter.showSummarisedSearch = true;
    this.filter.showAdvancedSearch = false;
    this.filter.showSimpleSearch = false;

    //do the search
    this.saveFilterRefresh();
  }

  public onFilterTagsChange(filter: SubmissionFilter) {
    this.filter = filter;
    this.saveFilterRefresh();
  }

  public saveFilterRefresh() {
    this.refresh(this.filter);
  }

  @Emit("advanced-search-refresh")
  public refresh(filter: SubmissionFilter) {
    //emit
  }

  public displaySimpleSearch() {
    this.emitDisplaySimpleSearch(this.filter);
  }

  @Emit("display-simple-search")
  public emitDisplaySimpleSearch(filter: SubmissionFilter) {
    //emit
  }
}
