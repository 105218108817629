
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import GrantApp from "@/sg_copy/model/GrantApp";
import {
  ApplicantOrgDto,
  GrantAppDetailDto,
  SfPermission,
} from "@/sg_copy/swagger-generated";
import ErrorMessageCard from "@/sg_copy/components/ErrorMessageCard.vue";
import {apiErrorUtil} from "@/sg_copy/utils/ApiErrorUtil";
import Multiselect from "vue-multiselect";
import {namespace} from "vuex-class";
import {permissionService} from "@/sg_copy/service/PermissionService";
import handleApiError from "@/shared/apiErrorUtil";

const mySubmissionStore = namespace("mySubmissionStore");

@Component({
  components: {
    Multiselect,
    ErrorMessageCard,
  },
})
export default class AssignOrgModal extends Vue {
  private static ALL_PERMISSIONS = [
    SfPermission.GRANT_APP_CREATE,
    SfPermission.GRANT_APP_WRITE,
  ];

  @Prop() private app!: GrantApp;
  private appDetail: GrantAppDetailDto = null;
  private orgList: Array<ApplicantOrgDto> = null;
  private error = false;
  private selectedPermissions: Array<SfPermission> = [];
  private orgSelected = null;
  private options: Array<any> = [];
  private loading = true;
  private validation = null;

  @mySubmissionStore.Action
  public getApp;

  @mySubmissionStore.Action
  public getUser;

  @mySubmissionStore.Action
  public assignOrg;

  created() {
    if (this.app) {
      this.load();
    }
  }

  @Watch("orgSelected")
  onOrgSelected(val) {
    if (val && val.id !== "0") {
      this.validation = null;
    }
  }

  @Watch("selectedPermissions")
  onSelectedChange() {
    this.updateOptionFromList();
  }

  public load() {
    this.loading = true;
    this.error = false;
    this.appDetail = null;
    this.validation = null;
    this.selectedPermissions = AssignOrgModal.ALL_PERMISSIONS;

    const promiseApp = this.getApp(this.app.id)
      .then(response => (this.appDetail = response.data))
      .catch(() => (this.error = true));

    const promiseUser = this.getUser()
      .then(response => {
        // Only show the orgs for which the user has at least one of the required permissions
        this.orgList = response.data.applicantOrgs.filter(role =>
          AssignOrgModal.ALL_PERMISSIONS.some(
            p => role.permissions.indexOf(p) >= 0
          )
        );
      })
      .catch(() => (this.error = true));

    Promise.all([promiseApp, promiseUser]).then(() => {
      if (!this.appDetail || !this.orgList) {
        this.error = true;
        this.$bvModal.hide("assign-org-modal");
        const title = "Unable to load application detail";
        apiErrorUtil.showApiError(this.app, title);
      } else {
        this.loading = false;
      }

      if (!this.loading) {
        this.orgSelected = null;
        this.updateOptionFromList();
      }
    });
  }

  public updateOptionFromList() {
    if (!this.orgList) {
      return;
    }

    let permissions = this.selectedPermissions;
    if (permissions.length === 0) {
      permissions = AssignOrgModal.ALL_PERMISSIONS;
    }

    this.options = [];
    let orgInList = false;
    for (const role of this.orgList) {
      if (permissionService.hasPermissions(role, permissions)) {
        this.options.push({
          text: role.orgName,
          id: role.orgId,
        });
        if (this.orgSelected != null && role.orgId === this.orgSelected.id) {
          orgInList = true;
        }
      }
    }
    if (!orgInList) {
      this.orgSelected = null;
    }
  }

  public assignAppToOrg(bvModalEvt) {
    bvModalEvt.preventDefault();
    this.app.processing = true;

    if (this.orgSelected === null || this.orgSelected.id === "0") {
      this.validation = "Please select an organisation.";
      this.app.processing = false;
      return;
    }

    const org = {
      orgId: this.orgSelected.id,
      orgName: this.options.filter(value => value.id === this.orgSelected.id)[0]
        .text,
    };

    const data = {
      appId: this.app.id,
      orgId: org.orgId,
    };

    this.assignOrg(data)
      .then(() => {
        this.emitOrgChanged();
        this.app.processing = true;
        this.$bvModal.hide("assign-org-modal");

        const message =
          "Application " + this.app.header + " assigned to org " + org.orgName;
        this.$bvToast.toast(message, {
          title: "Success",
          autoHideDelay: 5000,
          variant: "success",
          solid: true,
        });
      })
      .catch(error => {
        this.app.processing = false;
        this.error = true;
        handleApiError(
          error,
          this,
          "Error assigning org for application " + this.app.header
        );
      });
  }

  @Emit("org-changed")
  public emitOrgChanged() {
    //emit
  }

  get permissionOptions() {
    return AssignOrgModal.ALL_PERMISSIONS.map(permission => ({
      value: permission,
      text: permissionService.getDescriptionFor(permission),
    }));
  }
}
