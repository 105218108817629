import {GrantAppDetailDto} from "@/sg_copy/swagger-generated";

export function canDelete(appDetailDto: GrantAppDetailDto): boolean {
  return (
    !hasBeenSubmitted(appDetailDto) && hasNoChildApplications(appDetailDto)
  );
}

export function hasBeenSubmitted(appDetailDto: GrantAppDetailDto): boolean {
  // Instead of simply checking status === GrantAppDtoStatusEnum.SUBMITTED,
  // this checks submitDate != null. This is to detect applications
  // that was submitted before but has now been reopened
  return appDetailDto.responses[0].submitDate != null;
}

export function hasNoChildApplications(
  appDetailDto: GrantAppDetailDto
): boolean {
  const numActiveChildApps = appDetailDto.childApps.filter(
    (grantApp: {removed: boolean}) => {
      return grantApp.removed === false;
    }
  );
  return numActiveChildApps.length === 0;
}
