
import {
  GrantAppDto, GrantAppTinyDto,
  SubmissionListDto,
  UserDto,
} from "@/sg_copy/swagger-generated";
import {Component, Prop, Vue} from "vue-property-decorator";
import InfiniteLoading, {StateChanger} from "vue-infinite-loading";
import AppSubmissionCard from "@/sg_copy/components/AppSubmissionCard.vue";
import GrantApp from "@/sg_copy/model/GrantApp";
import AppDetailModal from "@/sg_copy/components/AppDetailModal.vue";
import ChangeUserModal from "@/sg_copy/components/ChangeUserModal.vue";
import ShareApplicationModal from "@/sg_copy/components/ShareApplicationModal.vue";
import {namespace} from "vuex-class";
import handleApiError from "@/shared/apiErrorUtil";
import AssignOrgModal from "@/sg_copy/components/AssignOrgModal.vue";
import {AppDropdownAction} from "@/sg_copy/model/AppDropdownAction";
import SubmissionsSearch from "@/sg_copy/components/SubmissionsSearch.vue";
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";
import SimpleSearch from "@/sg_copy/components/SimpleSearch.vue";
import CreateVariationModal from "@/sg_copy/components/CreateVariationModal.vue";

const mySubmissionStore = namespace("mySubmissionStore");
const auth = namespace("auth");

@Component({
  components: {
    CreateVariationModal,
    SimpleSearch,
    SubmissionsSearch,
    InfiniteLoading,
    AppSubmissionCard,
    AppDetailModal,
    ChangeUserModal,
    ShareApplicationModal,
    AssignOrgModal,
  },
})
export default class Submissions extends Vue {
  @Prop() private appIdPath: string;
  @Prop() private archived: boolean;
  @Prop() private user: UserDto;

  private submissions: Array<GrantApp> = [];

  private result: SubmissionListDto = null;

  private infiniteId = 0;
  private infiniteState: StateChanger = null;
  private displayedModal: GrantApp = null;
  private variationModalApp: GrantAppTinyDto = null;
  private showSubmissionModal: boolean;
  private grantAppIdFocus: number;
  private filter: SubmissionFilter = new SubmissionFilter();

  @mySubmissionStore.Action
  public getSubmissions;

  @mySubmissionStore.Action
  public getAppSummary;

  @mySubmissionStore.Action
  removeOrg;

  @auth.Action
  public getToken;

  public onFilterChange(filter: SubmissionFilter) {
    this.grantAppIdFocus = null;
    this.filter = filter;
    this.refresh();
  }

  public refresh() {
    this.submissions.length = 0;
    this.result = null;
    this.infiniteId++;
  }

  public appModalClosed() {
    this.grantAppIdFocus = null;
    this.showSubmissionModal = false;
  }

  public updateApp() {
    this.displayedModal.processing = true;
    this.getAppSummary(this.displayedModal.id)
      .then(response => {
        this.displayedModal.app = response.data;
        this.changeFocusedApp(this.displayedModal.id);
      })
      .catch(error => handleApiError(error, this))
      .finally(() => (this.displayedModal.processing = false));
  }

  public showModal(app: GrantApp): void {
    this.displayedModal = app;
    this.showSubmissionModal = true;
    this.changeFocusedApp(app.id);
    this.$nextTick(() => {
      this.$bvModal.show("app-detail-modal");
    });
  }

  public showVariationModal(app: GrantAppTinyDto): void {
    //
    this.variationModalApp = app;
    this.$bvModal.show("create-variation-modal");
  }

  public handleDropDownAction(app: GrantApp, action: AppDropdownAction): void {
    this.displayedModal = app;
    // Why does this need to happen on the next tick?
    // if no next tick, the displayedModal is not populated in child component
    this.$nextTick(() => {
      switch (action) {
        case "change":
          this.$bvModal.show("change-user-modal");
          break;
        case "share":
          this.$bvModal.show("share-application-modal");
          break;
        case "assignOrg":
          this.$bvModal.show("assign-org-modal");
          break;
        case "removeOrg":
          this.removeOrgFromSubmission(app);
          break;
      }
    });
  }

  async removeOrgFromSubmission(app: GrantApp) {
    try {
      await this.removeOrg(app.app.applicationId);
      try {
        const response = await this.getAppSummary({
          appId: app.app.applicationId,
        });
        this.displayedModal.app = response.data;
        this.grantAppIdFocus = app.app.applicationId;
      } catch (error) {
        // In case of error here it means the application is no longer visible.
        // We should remove it from the list and not show an error to the user
        app.remove();
        this.grantAppIdFocus = null;
      }
      this.$bvToast.toast("Org removed", {
        title: "Success",
        variant: "success",
      });
    } catch (error) {
      // This is an error in the remove call, we have to show an error to the user since the
      // operation they wanted to perform wasn't done
      handleApiError(error, this, "Failed to remove Org from Submission");
    }
  }

  public changeFocusedApp(appId: number): void {
    this.grantAppIdFocus = appId;
  }

  public loadMore() {
    if ((this.result && this.result.nextStartIndex !== -1) || !this.result) {
      this.getSubmissions({
        result: this.result,
        filter: this.filter,
        archived: this.archived,
      })
        .then(response => {
          this.result = response.data;
          for (let ga of this.result.data) {
            const gav = new GrantApp(ga, this.user);
            this.submissions.push(gav);
            if (this.isAppInFocus(ga)) {
              this.$nextTick(() => this.scrollToApp(gav));
            }
          }
          if (this.infiniteState) {
            if (this.result.nextStartIndex === -1) {
              this.infiniteState.complete();
            } else {
              this.infiniteState.loaded();
            }
          }
          if (this.shouldLoadMore()) {
            this.loadMore();
          }
        })
        .catch(error => {
          this.infiniteState.complete();
          handleApiError(error, this);
        });
    }
  }

  isAppInFocus(ga: GrantAppDto): boolean {
    const isAppOnPath =
      this.appIdPath && this.appIdPath === ga.applicationId.toString();
    const isAppInFocus = ga.applicationId === this.grantAppIdFocus;
    return isAppOnPath || isAppInFocus;
  }

  scrollToApp(gav: GrantApp): void {
    if (document) {
      const element = document.getElementById(gav.app.applicationId.toString());
      if (element) {
        element.scrollIntoView(true);
      }
    }
    if (this.appIdPath || this.showSubmissionModal) {
      this.showModal(gav);
    } else {
      gav.toggleExpanded(true);
    }
  }

  shouldLoadMore(): boolean {
    return (
      (this.appIdPath || this.grantAppIdFocus) &&
      !this.displayedModal &&
      this.result &&
      this.result.nextStartIndex !== -1
    );
  }

  public infiniteHandler($state) {
    this.infiniteState = $state;
    this.loadMore();
  }
}
