
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import GrantApp from "@/sg_copy/model/GrantApp";
import {
  ApplicantOrgMemberDto,
  GrantAppDetailDto,
  SfPermission,
} from "@/sg_copy/swagger-generated";
import ErrorMessageCard from "@/sg_copy/components/ErrorMessageCard.vue";
import Multiselect from "vue-multiselect";
import {namespace} from "vuex-class";
import handleApiError from "@/shared/apiErrorUtil";
import {permissionService} from "../service/PermissionService";

const mySubmissionStore = namespace("mySubmissionStore");

@Component({
  components: {
    ErrorMessageCard,
    Multiselect,
  },
})
export default class ChangeUserModal extends Vue {
  @Prop() private app!: GrantApp;
  private appDetail: GrantAppDetailDto = null;
  private userList: Array<ApplicantOrgMemberDto> = null;
  private error = false;
  private selected: Array<SfPermission> = [];
  private usernameSelected = null;
  private options: Array<any> = [];
  private loading = true;
  private validation: string = null;

  private readonly ALL_PERMISSIONS = [
    SfPermission.GRANT_APP_CREATE,
    SfPermission.GRANT_APP_WRITE,
    SfPermission.GRANT_APP_READ,
    SfPermission.GRANT_APP_SUBMIT,
  ];

  @mySubmissionStore.Action
  public getApp;

  @mySubmissionStore.Action
  public changeUser;

  @mySubmissionStore.Action
  public getMembers;

  created() {
    if (this.app) {
      this.load();
    }
  }

  @Watch("usernameSelected")
  onUsernameSelected(val) {
    if (val && val.id !== "0") {
      this.validation = null;
    }
  }

  @Watch("selected")
  onSelectedChange() {
    this.updateOptionFromList();
  }

  public load() {
    this.loading = true;
    this.error = false;
    this.appDetail = null;
    this.validation = null;
    this.selected = this.ALL_PERMISSIONS;

    const promiseApp = this.getApp(this.app.id)
      .then(response => {
        this.appDetail = response.data;
        if (!this.appDetail) {
          this.error = true;
        }
      })
      .catch(() => (this.error = true));

    const data = {
      appId: this.app.id,
      operation: "change",
    };
    const promiseUser = this.getMembers(data)
      .then(response => (this.userList = response.data.data))
      .catch(() => (this.error = true));

    Promise.all([promiseApp, promiseUser]).then(() => {
      if (!this.appDetail || !this.userList) {
        this.error = true;
        this.$bvModal.hide("change-user-modal");
        handleApiError(
          "Unable to load application detail",
          this,
          "Unable to load application detail"
        );
      } else {
        this.loading = false;
      }

      if (!this.loading) {
        this.usernameSelected = null;
        this.updateOptionFromList();
      }
    });
  }

  public updateOptionFromList() {
    if (!this.userList) {
      return;
    }

    let permissions = this.selected;
    if (permissions.length === 0) {
      permissions = this.ALL_PERMISSIONS;
    }

    this.options = [];
    let userInList = false;
    for (const role of this.userList) {
      if (permissionService.hasPermissions(role, permissions)) {
        const userId = role.user.id.toString();
        this.options.push({
          text: role.user.name,
          id: userId,
        });

        if (userId === this.usernameSelected?.id) {
          userInList = true;
        }
      }
    }
    if (!userInList) {
      this.usernameSelected = null;
    }
  }

  public changeApplicationUser(bvModalEvt) {
    bvModalEvt.preventDefault();
    this.app.processing = true;

    if (this.usernameSelected === null || this.usernameSelected.id === "0") {
      this.validation = "Please select a user.";
      this.app.processing = false;
      return;
    }

    if (this.usernameSelected.id !== this.appDetail.userId.toString()) {
      const data = {
        appId: this.app.id,
        userId: {
          id: parseInt(this.usernameSelected.id),
        },
      };

      this.changeUser(data)
        .then(() => {
          this.app.processing = false;
          this.emitUserChanged();
          this.$bvModal.hide("change-user-modal");

          const newUserName = this.options.filter(
            value => value.id === this.usernameSelected.id
          )[0].text;
          const message =
            "Application " +
            this.app.header +
            " user changed to " +
            newUserName;
          this.$bvToast.toast(message, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true,
          });
        })
        .catch(error => {
          this.app.processing = false;
          this.error = true;
          handleApiError(
            error,
            this,
            "Error changing user for application " + this.app.header
          );
        });
    } else {
      this.app.processing = false;
      this.$nextTick().then(() => {
        this.$bvModal.hide("change-user-modal");
      });
    }
  }

  @Emit("user-changed")
  public emitUserChanged() {
    //emits
  }

  get permissionOptions() {
    return this.ALL_PERMISSIONS.map(permission => ({
      value: permission,
      text: permissionService.getDescriptionFor(permission),
    }));
  }
}
