
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";

@Component({})
export default class SimpleSearch extends Vue {
  @Prop() private filter: SubmissionFilter;

  public saveFilterRefresh() {
    this.refresh(this.filter);
  }

  @Emit("simple-search-refresh")
  public refresh(filter: SubmissionFilter) {
    //emit
  }
}
