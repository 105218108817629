
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";
import {UserDto} from "@/sg_copy/swagger-generated";

const ORG_FILTER = "orgFilter";
const ROUND_FILTER = "roundFilter";
const STATUS_FILTER = "statusFilter";
const OWNED_BY_FILTER = "ownedByFilter";
const SHARED_WITH_FILTER = "sharedWithFilter";
const SUBMITTED_DATE_FILTER = "submittedDateFilter";
const DUE_DATE_FILTER = "dueDateFilter";
const FORM_NAME_FILTER = "formNameFilter";
const RESPONSE_FILTER = "responseFilter";
const INSTANCE_FILTER = "instanceFilter";
const CLASSIE_ORG_TYPE_FILTER = "classieOrgTypeFilter";
const REGION_FILTER = "regionFilter";

@Component({})
export default class FilterTags extends Vue {
  @Prop() private filter: SubmissionFilter;
  @Prop() private user: UserDto;
  @Prop() private statusOptions;
  @Prop() private classieOrgTypeOptions;

  private filters = [];

  mounted() {
    this.getFilterTags();
  }

  public removeFilterTags(tag) {
    if (tag.value === ORG_FILTER) {
      this.filter.orgSelected = undefined;
      this.filters = this.filters.filter(f => f.value !== ORG_FILTER);
    }

    if (tag.value === ROUND_FILTER) {
      this.filter.roundSelected = undefined;
      this.filters = this.filters.filter(f => f.value !== ROUND_FILTER);
    }

    if (tag.value === STATUS_FILTER) {
      this.filter.statusSelected = undefined;
      this.filters = this.filters.filter(f => f.value !== STATUS_FILTER);
    }

    if (tag.value === OWNED_BY_FILTER) {
      this.filter.ownedBySelected = undefined;
      this.filters = this.filters.filter(f => f.value !== OWNED_BY_FILTER);
    }

    if (tag.value === SHARED_WITH_FILTER) {
      this.filter.sharedWithSelected = undefined;
      this.filters = this.filters.filter(f => f.value !== SHARED_WITH_FILTER);
    }

    if (tag.value === SUBMITTED_DATE_FILTER) {
      this.filter.submittedDateTo = undefined;
      this.filter.submittedDateFrom = undefined;
      this.filters = this.filters.filter(
        f => f.value !== SUBMITTED_DATE_FILTER
      );
    }

    if (tag.value === DUE_DATE_FILTER) {
      this.filter.dueDateFrom = undefined;
      this.filter.dueDateTo = undefined;
      this.filters = this.filters.filter(f => f.value !== DUE_DATE_FILTER);
    }

    if (tag.value === FORM_NAME_FILTER) {
      this.filter.formNameSelected = undefined;
      this.filters = this.filters.filter(f => f.value !== FORM_NAME_FILTER);
    }

    if (tag.value === RESPONSE_FILTER) {
      this.filter.responseSelected = undefined;
      this.filters = this.filters.filter(f => f.value !== RESPONSE_FILTER);
    }

    if (tag.value === INSTANCE_FILTER) {
      this.filter.instanceSelected = undefined;
      this.filters = this.filters.filter(f => f.value !== INSTANCE_FILTER);
    }

    if (tag.value === CLASSIE_ORG_TYPE_FILTER) {
      this.filter.classieOrgIdSelected = undefined;
      this.filters = this.filters.filter(f => f.value !== CLASSIE_ORG_TYPE_FILTER);
    }

    if (tag.value === REGION_FILTER) {
      this.filter.regionSelected = undefined;
      this.filters = this.filters.filter(f => f.value !== REGION_FILTER);
    }

    if (this.filters.length === 0) {
      this.displaySimpleSearch();
    }

    this.saveFilterRefresh();
  }

  public getFilterTags() {
    //clear existing filter
    this.filters.splice(0);

    if (this.filter.orgSelected != null) {
      this.filters.push({
        value: ORG_FILTER,
        text:
          "Organisation: " +
          this.user.applicantOrgs.find(
            org => org.orgId === this.filter.orgSelected
          ).orgName,
      });
    }
    if (this.isNotEmpty(this.filter.roundSelected)) {
      this.filters.push({
        value: ROUND_FILTER,
        text: "Round: " + this.filter.roundSelected,
      });
    }
    if (this.filter.statusSelected != null) {
      this.filters.push({
        value: STATUS_FILTER,
        text:
          "Status: " +
          this.statusOptions.find(r => r.value === this.filter.statusSelected)
            .text,
      });
    }
    if (this.isNotEmpty(this.filter.ownedBySelected)) {
      this.filters.push({
        value: OWNED_BY_FILTER,
        text: "Owned by: " + this.filter.ownedBySelected,
      });
    }
    if (this.isNotEmpty(this.filter.sharedWithSelected)) {
      this.filters.push({
        value: SHARED_WITH_FILTER,
        text: "Shared with: " + this.filter.sharedWithSelected,
      });
    }
    if (
      this.isNotEmpty(this.filter.submittedDateTo) ||
      this.isNotEmpty(this.filter.submittedDateFrom)
    ) {
      let str =
        "Submitted date: " +
        this.getDateRangeString(
          this.filter.submittedDateFrom,
          this.filter.submittedDateTo
        );
      this.filters.push({value: SUBMITTED_DATE_FILTER, text: str});
    }
    if (
      this.isNotEmpty(this.filter.dueDateTo) ||
      this.isNotEmpty(this.filter.dueDateFrom)
    ) {
      let str =
        "Due date: " +
        this.getDateRangeString(this.filter.dueDateFrom, this.filter.dueDateTo);
      this.filters.push({value: DUE_DATE_FILTER, text: str});
    }
    if (this.isNotEmpty(this.filter.formNameSelected)) {
      this.filters.push({
        value: FORM_NAME_FILTER,
        text: "Form name: " + this.filter.formNameSelected,
      });
    }
    if (this.isNotEmpty(this.filter.responseSelected)) {
      this.filters.push({
        value: RESPONSE_FILTER,
        text: "Response text: " + this.filter.responseSelected,
      });
    }
    if (this.isNotEmpty(this.filter.instanceSelected)) {
      this.filters.push({
        value: INSTANCE_FILTER,
        text:
          "Funder: " +
          this.user.instances.find(
            i => i.instanceId === this.filter.instanceSelected
          ).instanceName,
      });
    }
    if (this.filter.classieOrgIdSelected != null) {
      this.filters.push({
        value: CLASSIE_ORG_TYPE_FILTER,
        text:
          "Type of Funder: " +
          this.classieOrgTypeOptions.find(r => r.id === this.filter.classieOrgIdSelected)
            .name,
      });
    }
    if (this.filter.regionSelected != null) {
      this.filters.push({
        value: REGION_FILTER,
        text:
          "Region: " + this.filter.regionSelected,
      });
    }
  }

  public getDateRangeString(from, to) {
    let str = "";
    if (from === null || from === "") {
      str += "any to ";
    } else {
      str += from + " to ";
    }
    if (to === null || to === "") {
      str += "any";
    } else {
      str += to;
    }
    return str;
  }

  public isNotEmpty(str): boolean {
    return str != null && str !== "";
  }

  public displaySimpleSearch() {
    this.filters = [];
    this.emitDisplaySimpleSearch(this.filter);
  }

  public saveFilterRefresh() {
    this.refresh(this.filter);
  }

  @Emit("filter-tags-refresh")
  public refresh(filter: SubmissionFilter) {
    //emit
  }

  @Emit("display-simple-search")
  public emitDisplaySimpleSearch(filter: SubmissionFilter) {
    //emit
  }

  @Watch("filter.showSummarisedSearch")
  public onSummarisedSearchChange() {
    if (this.filter.showSummarisedSearch) {
      this.getFilterTags();
    }
  }
}
