import Vue from "vue";
import Vuex from "vuex";
import NotificationStore from "@/sg_copy/store/NotificationStore";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    NotificationStore,
  },
});

export default store;
