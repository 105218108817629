
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import GrantApp from "@/sg_copy/model/GrantApp";
import {GrantAppDetailDto, UserDto} from "@/sg_copy/swagger-generated";
import ResponseCard from "@/sg_copy/components/ResponseCard.vue";
import ErrorMessageCard from "@/sg_copy/components/ErrorMessageCard.vue";
import Response from "@/sg_copy/model/Response";
import {apiErrorUtil} from "@/sg_copy/utils/ApiErrorUtil";
import {namespace} from "vuex-class";

const mySubmissionStore = namespace("mySubmissionStore");

@Component({
  components: {
    ResponseCard,
    ErrorMessageCard,
  },
})
export default class AppDetailModal extends Vue {
  @Prop()
  private app!: GrantApp;
  private appDetail: GrantAppDetailDto = null;
  private responses: Array<Response> = [];
  private error = false;
  private shares: Array<UserDto> = [];
  private readMoreExpanded = false;

  @mySubmissionStore.Action
  public getApp;

  @Watch("appRemoved")
  onDeleted() {
    this.closeModal();
  }

  closeModal() {
    this.$bvModal.hide("app-detail-modal");
  }

  @Emit("app-modal-closed")
  emitCloseModal() {
    //emits
  }

  public get appRemoved(): boolean {
    return this.app && this.app.isRemoved;
  }

  public get userString(): string {
    let result = "";
    this.appDetail.shares.forEach((user, index) => {
      result =
        result +
        user.name +
        (user.id === this.app.user.id ? " (me)" : "") +
        (index < this.appDetail.shares.length - 1 ? ", " : "");
    });
    return result;
  }

  created() {
    if (this.app) {
      this.load();
    }
  }

  // noinspection JSUnusedLocalSymbols
  @Emit("change-user")
  public emitChangeUser(app: GrantApp): void {
    // emits
  }

  public load() {
    this.error = false;
    this.shares = [];
    this.appDetail = null;
    this.responses.length = 0;
    this.readMoreExpanded = false;


    this.getApp(this.app.id)
      .then(response => {
        this.appDetail = response.data;
        if (!this.appDetail) {
          this.error = true;
          return;
        }

        for (const appResponse of this.appDetail.responses) {
          this.responses.push(new Response(appResponse, this.app.user));
        }

        if (this.appDetail.shares && !this.appDetail.shareExpired) {
          this.shares = this.appDetail.shares.sort((a, b) => {
            if (a.id === this.app.user.id) {
              return -1;
            }
            if (b.id === this.app.user.id) {
              return 1;
            }
            return 0;
          });
        }
      })
      .catch(() => {
        this.error = true;
        this.closeModal();
        apiErrorUtil.showApiError(
          this.app,
          "Unable to load application detail"
        );
      });
  }
}
